// React
import React from 'react';
import Helmet from 'react-helmet';

// Gatsby
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';

// Third-party
import get from 'lodash/get';
import moment from 'moment';

// Core components
import Layout from '../components/layout';

import RunSlipRow from '../components/runSlipRow';
import RunSlipRowETDiff from '../components/runSlipRowETDiff';
//import heroStyles from '../components/hero.module.css';

class RunTemplate extends React.Component {
  render() {
    const run = get(this.props, 'data.contentfulDragstripRun');
    const siteTitle = get(this.props, 'data.site.siteMetadata.title');

    let previousLink;
    if (run.round > 1) {
      previousLink = (
        <div style={{ float: 'left', marginBottom: '10px' }}>
          <Link
            to={`/run/${run.slug.substring(0, run.slug.length - 1)}${(run.round - 1).toString()}`}
          >
            &lt;- Previous run
          </Link>
        </div>
      );
    }

    let nextLink;
    if (run.round < this.props.pageContext.itemCount) {
      nextLink = (
        <div style={{ float: 'right', marginBottom: '10px' }}>
          <Link
            to={`/run/${run.slug.substring(0, run.slug.length - 1)}${(run.round + 1).toString()}`}
          >
            Next run -&gt;
          </Link>
        </div>
      );
    }

    let timeslip;
    if (run.timeslip) {
      timeslip = (
        <>
          <div style={{ margin: '20px 4px 0 4px' }}>Timeslip:</div>
          <div style={{ margin: '4px', border: '1px solid gray', padding: '0 10px' }}>
            <Img alt="Timeslip" fluid={run.timeslip.fluid} />
          </div>
        </>
      );
    }

    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff', color: '#373f49' }}>
          <Helmet title={`${run.runName} | ${siteTitle}`} />
          <div className="wrapper">
            <h1 className="section-headline">{run.runName}</h1>
            {previousLink}
            {nextLink}
            <div style={{ clear: 'both' }} />
            <div style={{ float: 'left' }}>
              <table>
                <tr>
                  <td style={{ width: '150px' }}>Date/time:</td>
                  <td>{moment(run.dateTime).format('MM/DD/YYYY hh:mm:ss A')}</td>
                </tr>
                <tr>
                  <td>Run #:</td>
                  <td>{run.round}</td>
                </tr>
                <tr>
                  <td>DA:</td>
                  <td>{run.densityAltitude}</td>
                </tr>
                <tr>
                  <td>Temperature:</td>
                  <td>{run.temperature}</td>
                </tr>
                <tr>
                  <td>Launch RPM:</td>
                  <td>{run.launchRpm}</td>
                </tr>
                <tr>
                  <td>Launch technique:</td>
                  <td>{run.launchTechnique.name}</td>
                </tr>
                <tr>
                  <td>Front wheels/tires:</td>
                  <td>{run.event.frontWheelsTires ? run.event.frontWheelsTires.name : ''}</td>
                </tr>
                <tr>
                  <td>Rear wheels/tires:</td>
                  <td>{run.event.rearWheelsTires ? run.event.rearWheelsTires.name : ''}</td>
                </tr>
                <tr>
                  <td>Rear tire pressure:</td>
                  <td>{run.tirePressure}</td>
                </tr>
                <tr>
                  <td>Gas tank level:</td>
                  <td>{run.event.startingGasTankLevel}</td>
                </tr>
                <tr>
                  <td>Tree type:</td>
                  <td>{run.treeType}</td>
                </tr>
                <tr>
                  <td>Opponent:</td>
                  <td>{run.opponentDescription}</td>
                </tr>
              </table>
            </div>
            <div style={{ float: 'right' }}>
              <div style={{ textAlign: 'center' }}>(My car in bold)</div>
              <div style={{ border: '1px solid gray', marginTop: '4px', padding: '8px 18px' }}>
                <table>
                  <RunSlipRow run={run} label="Car #" fieldName="carNumber" decimalPlaces={0} />
                  <RunSlipRow run={run} label="Dial-in" fieldName="dialIn" decimalPlaces={4} />
                  <RunSlipRow run={run} label="RT" fieldName="reactionTime" decimalPlaces={4} />
                  <RunSlipRow run={run} label="60'" fieldName="sixtyFootET" decimalPlaces={4} />
                  <RunSlipRow
                    run={run}
                    label="330'"
                    fieldName="threeThirtyFootET"
                    decimalPlaces={4}
                  />
                  <RunSlipRow run={run} label="1/8 ET" fieldName="eighthMileET" decimalPlaces={4} />
                  <RunSlipRow
                    run={run}
                    label="1/8 MPH"
                    fieldName="eighthMileMPH"
                    decimalPlaces={2}
                  />
                  <RunSlipRow
                    run={run}
                    label="1000'"
                    fieldName="thousandFootET"
                    decimalPlaces={4}
                  />
                  <RunSlipRow
                    run={run}
                    label="1/4 ET"
                    fieldName="quarterMileET"
                    decimalPlaces={4}
                  />
                  <RunSlipRowETDiff run={run} />
                </table>
              </div>
            </div>
            <div style={{ clear: 'both' }} />
            <div style={{ margin: '10px 4px 0 4px' }}>Mods:</div>
            <div style={{ margin: '4px', border: '1px solid gray', padding: '0 10px' }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: run.event.mods.childMarkdownRemark.html,
                }}
              />
            </div>
            <div style={{ margin: '10px 4px 0 4px' }}>Run notes:</div>
            <div style={{ margin: '4px', border: '1px solid gray', padding: '0 10px' }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: run.runDescription.childMarkdownRemark.html,
                }}
              />
            </div>
            <div style={{ margin: '20px 4px 0 4px' }}>Event notes:</div>
            <div style={{ margin: '4px', border: '1px solid gray', padding: '0 10px' }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: run.event.eventNotes.childMarkdownRemark.html,
                }}
              />
            </div>
            {timeslip}
          </div>
        </div>
      </Layout>
    );
  }
}

export default RunTemplate;

export const pageQuery = graphql`
  query RunBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulDragstripRun(slug: { eq: $slug }) {
      slug
      runName
      round
      dateTime
      carNumber
      lane
      reactionTime
      sixtyFootET
      threeThirtyFootET
      eighthMileET
      eighthMileMPH
      thousandFootET
      quarterMileET
      quarterMileMPH
      opponentCarNumber
      opponentDescription
      opponentReactionTime
      opponentSixtyFootET
      opponentThreeThirtyFootET
      opponentEighthMileET
      opponentEighthMileMPH
      opponentThousandFootET
      opponentQuarterMileET
      opponentQuarterMileMPH
      etDiff
      densityAltitude
      temperature
      tirePressure
      treeType
      launchRpm
      launchTechnique {
        name
      }
      event {
        frontWheelsTires {
          name
        }
        rearWheelsTires {
          name
        }
        startingGasTankLevel
        mods {
          childMarkdownRemark {
            html
          }
        }
        eventNotes {
          childMarkdownRemark {
            html
          }
        }
        track {
          slug
        }
      }
      runDescription {
        childMarkdownRemark {
          html
        }
      }
      timeslip {
        fluid(maxWidth: 800, background: "rgb:ffffff") {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
    }
  }
`;
