// React
import React from 'react';

class Template extends React.Component {
  render() {
    const { run, decimalPlaces, fieldName, label } = this.props;
    const amIInLeftLane = run.lane === 'Left';

    const opponentFieldName = `opponent${fieldName
      .substring(0, 1)
      .toUpperCase()}${fieldName.substring(1)}`;

    let myValue = run[fieldName] ? run[fieldName] : '-';
    let opponentValue = run[opponentFieldName] ? run[opponentFieldName] : '-';

    if (decimalPlaces) {
      if (myValue && myValue !== '-') {
        myValue = myValue.toFixed(decimalPlaces);
      }

      if (opponentValue && opponentValue !== '-') {
        opponentValue = opponentValue.toFixed(decimalPlaces);
      }
    }

    const leftLaneValue = amIInLeftLane ? myValue : opponentValue;
    const rightLaneValue = amIInLeftLane ? opponentValue : myValue;

    const leftLaneStyle = { width: '90px' };
    const rightLaneStyle = { textAlign: 'right', width: '90px' };

    if (amIInLeftLane) {
      leftLaneStyle.fontWeight = 'bold';
    } else {
      rightLaneStyle.fontWeight = 'bold';
    }

    return (
      <tr>
        <td style={leftLaneStyle}>{leftLaneValue}</td>
        <td style={{ width: '120px', textAlign: 'center' }}>{label}</td>
        <td style={rightLaneStyle}>{rightLaneValue}</td>
      </tr>
    );
  }
}

export default Template;
